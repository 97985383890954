import React from "react";
import { Navigate } from "react-router-dom";
import { isValidToken } from "../helpers/token";
import useUserInfo from "../hooks/useUserInfo";
import LoadingPage from "./general/LoadingPage";
export default function PrivateRoute({ children }) {
  const { user, isLoading } = useUserInfo();

  if (isLoading) return <LoadingPage />;

  if (user && isValidToken()) return children;

  return <Navigate to="/" replace />;
}
