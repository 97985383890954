import React, { Suspense } from "react";
import LoadingPage from "./general/LoadingPage";

export function LazyComponent({
  component: Component,
  loader = <LoadingPage />,
}) {
  return (
    <Suspense fallback={loader}>
      <Component />
    </Suspense>
  );
}

export default function Async(component) {
  return () => <LazyComponent component={component} />;
}
