import React from "react";
import { Navigate } from "react-router-dom";
import { isValidToken } from "../helpers/token";
import useUserInfo from "../hooks/useUserInfo";

export default function RedirectRoute({ children }) {
  const { user, isLoading } = useUserInfo();

  if (isLoading) return "Checking session...";

  if (user && isValidToken()) return <Navigate to={`/admin/company/${user?.company}`} replace />;

  return children;
}
