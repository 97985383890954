import { useEffect } from "react";
import { useQuery } from "react-query";
import { getUserInfo } from "../helpers/api";
import { isValidToken } from "../helpers/token";
import { useSession } from "../context/SessionContext";

export default function useUserInfo() {
  const isValidTokenSession = isValidToken();
  const { user, setUser, logout } = useSession();
  const { data, isError, ...args } = useQuery("user", getUserInfo, {
    enabled: isValidTokenSession,
    refetchOnWindowFocus: true,
    onError() {
      logout();
    },
  });

  useEffect(() => {
    if (user || !data || isError) return;

    setUser(data);
  }, [data, isError, setUser, user]);

  return { user: data, isError, ...args };
}
