import { use100vh } from "react-div-100vh";
import Spinner from "./Spinner";

export default function LoadingPage() {
  const height = use100vh();
  const h = height - 180;
  return (
    <div
      style={{ height: h }}
      className="d-flex flex-column align-items-center justify-content-center"
    >
      <Spinner style={{ fontSize: 24 }} spinProps={{ size: "large" }} />
      <span className="fw-bold d-block text-white mx-auto mt-4">
        Cargando...
      </span>
    </div>
  );
}
