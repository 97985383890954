import { useEffect, useState } from "react";

const DEFAULT_OPTIONS = {
  config: {
    attributes: true,
    subtree: true,
    childList: true,
  },
};

export default function useMutationObservable(
  targetEl,
  cb,
  options = DEFAULT_OPTIONS
) {
  const [observer, setObserver] = useState(null);

  useEffect(() => {
    if (!targetEl) return;
    const obs = new MutationObserver(cb);
    setObserver(obs);
  }, [options, targetEl]);

  useEffect(() => {
    if (!observer || !targetEl) return;
    const { config } = options;
    observer.observe(targetEl, config);
    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [observer, targetEl, options]);
}
