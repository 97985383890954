import React from "react";
import Layout from "./components/general/Layout";
import PrivateRoute from "./components/PrivateRoute";
import RedirectRoute from "./components/RedirectRoute";
import routers from "./config";
import { Routes, Route, BrowserRouter } from "react-router-dom";
export default function Routers() {
  return (
    <BrowserRouter>
      <Routes>
        {routers.map(
          (
            {
              path,
              element: Element,
              private: isPrivate,
              redirect: isRedirect,
              layout = true,
              ...props
            },
            i
          ) => {
            const key = path || i;
            const AuthRoute = isPrivate
              ? PrivateRoute
              : isRedirect
              ? RedirectRoute
              : null;

            const Wrapper = () =>
              AuthRoute === null ? (
                <Element />
              ) : (
                <AuthRoute>
                  <Element />
                </AuthRoute>
              );

            return (
              <Route
                element={
                  <Layout header={layout}>
                    <Wrapper />
                  </Layout>
                }
                path={path}
                key={key}
                {...props}
              />
            );
          }
        )}
      </Routes>
    </BrowserRouter>
  );
}
