import { useState, useCallback } from "react";
import { useQueryClient } from "react-query";
import { removeToken } from "../helpers/token";

const DEFAULT_STATE = { user: null };

export default function useSessionContext() {
  const [session, setSession] = useState(DEFAULT_STATE);
  const queryClient = useQueryClient();

  const setUser = useCallback((user) => {
    setSession((prev) => ({
      ...prev,
      user: {
        ...user,
        isAdmin: user?.role === "admin",
        isValidator: user?.role === "validator",
      },
    }));
  }, []);

  const logout = useCallback(() => {
    queryClient.invalidateQueries("user");
    queryClient.removeQueries();
    removeToken();
    setSession(DEFAULT_STATE);
  }, [queryClient]);

  return { ...session, setSession, setUser, logout };
}
